<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </div>
    </v-col>
    <template v-else>
      <v-btn
        color="primary"
        to="/product-management/neu">
        Neues Produkt
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="products"
        :loading="loading"
        class="elevation-1 mt-4"
        disable-pagination
        hide-default-footer
      >
        <template
          slot="item"
          slot-scope="props">
          <tr
            class="-clickable"
            @click="openProductDetails(props.item.id)">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.manufacturer }}</td>
            <td>
              <router-link :to="'/product-management/' + props.item.id">
                <v-btn
                  color="primary"
                  small>
                  <v-icon>search</v-icon>
                </v-btn>
              </router-link>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import ProductsAPI from '@/api/products'

export default {
  data() {
    return {
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Hersteller',
          sortable: true,
          value: 'manufacturer',
        },
        {
          text: 'Aktionen',
          sortable: false,
          value: 'id',
        },
      ],
      products: [],
      loading: true,
    }
  },
  created() {
    this.loading = true
    ProductsAPI.getProductModels().then((data) => {
      this.products = Object.values(data.product_models)
      this.loading = false
    })
  },
  methods: {
    openProductDetails(id) {
      this.$router.push(`/product-management/${id}`)
    },
  },
}
</script>
